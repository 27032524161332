import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Alert,
} from "reactstrap"

import ReactPaginate from "react-paginate"
import Select from "react-select"

import { connect } from "react-redux"
import axios from "axios"
import { utils, writeFile } from "xlsx"
import { useNavigate } from "react-router-dom"

// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import "./Empresas.scss"

const Sucursales = props => {
  document.title = "Sucursales | LS-DA - Softlogy"

  const navigate = useNavigate()

  const [sucursales, setSucursales] = useState([])
  const [searchTerm] = useState("")

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10

  const [loading, setLoading] = useState(false)

  const [empresas, setEmpresas] = useState([])
  const [nitreceptor, setNitreceptor] = useState("")

  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  const [modalOpen, setModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [selectedSucursalId, setSelectedSucursalId] = useState(null)
  const [newSucursal, setNewSucursal] = useState({
    empresa: "",
    nombre: "",
    direccion: "",
    active: true,
    mailnotificacion: "",
    codigointerno: "",
  })

  const [modalUsuariosOpen, setModalUsuariosOpen] = useState(false)
  const [modalAssignUsuariosOpen, setModalAssignUsuariosOpen] = useState(false)
  const [usuariosAsignados, setUsuariosAsignados] = useState([])
  const [allUsuarios, setAllUsuarios] = useState([])
  const [selectedUsuarios, setSelectedUsuarios] = useState([])
  const [, setSelectedEmpresaId] = useState(null)

  const fetchEmpresas = async () => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    if (!currentUser || !currentUser.empresas) {
      return
    }
    try {
      setEmpresas(currentUser.empresas)
    } catch (error) {
      console.error("Error fetching empresas data:", error)
    }
  }

  const fetchSucursales = async () => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    if (!currentUser || !currentUser.access) {
      return
    }

    setLoading(true)
    try {
      const response = await axios.get(
        `https://timbrador.ls-da.com/recepcion/hub-latam/sucursales?idEmpresa=${nitreceptor}`,
        {
          headers: {
            Authorization: `Bearer ${currentUser.access}`,
          },
        },
      )
      setSucursales(response.data.sucursales || [])
    } catch (error) {
      console.error("Error fetching sucursales data:", error)
    } finally {
      setLoading(false)
    }
  }

  const fetchUsuariosAsignados = async sucursalId => {
    try {
      const response = await axios.get(
        `https://timbrador.ls-da.com/recepcion/hub-latam/sucursales/usuarios?idEmpresa=${sucursalId}`,
      )
      setUsuariosAsignados(response.data.usuarios || [])
      setSelectedSucursalId(sucursalId) // Asegúrate de establecer el ID de la sucursal seleccionada
      setModalUsuariosOpen(true)
    } catch (error) {
      console.error("Error fetching usuarios asignados:", error)
    }
  }

  const fetchAllUsuarios = async empresaId => {
    try {
      const response = await axios.get(
        `https://timbrador.ls-da.com/recepcion/hub-latam/usuarios/01?idEmpresa=${empresaId}`,
      )
      setAllUsuarios(response.data.usuarios || [])
    } catch (error) {
      console.error("Error fetching all usuarios:", error)
    }
  }

  useEffect(() => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null
    if (currentUser) {
      const userGreeting = `Bienvenido, ${
        currentUser
          ? `${currentUser.nombre} ${currentUser.apellido}`
          : "Usuario"
      }`

      const breadcrumbItems = [
        { title: "LS-DA", link: "#" },
        { title: "Empresas", link: "#" },
        { title: "Sucursales", link: "#" },
      ]
      props.setBreadcrumbItems(userGreeting, breadcrumbItems)
    }
    fetchEmpresas()
  }, [props])

  const handleExportToExcel = () => {
    const worksheet = utils.json_to_sheet(sucursales)
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, "Sucursales")
    writeFile(workbook, "Sucursales.xlsx")
  }

  const handleSubmit = e => {
    e.preventDefault()
    fetchSucursales()
  }

  const filteredSucursales = sucursales.filter(sucursal =>
    sucursal.nombre.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredSucursales.slice(
    indexOfFirstItem,
    indexOfLastItem,
  )

  const data = {
    columns: [
      { label: "Nombre Tienda", field: "nombre", sort: "asc", width: 20 },
      { label: "Direccion", field: "direccion", sort: "asc", width: 75 },
      {
        label: "Correo Notificaciones",
        field: "mailnotificacion",
        sort: "asc",
        width: 200,
      },
      {
        label: "Codigo Interno",
        field: "codigointerno",
        sort: "asc",
        width: 75,
      },
      { label: "Activo", field: "active", sort: "asc", width: 75 },
      {
        label: "Acciones",
        field: "acciones",
        sort: "asc",
        width: 150,
      },
    ],
    rows: currentItems.map((sucursal, index) => ({
      id: sucursal._id,
      nombre: sucursal.nombre,
      direccion: sucursal.direccion,
      mailnotificacion: sucursal.mailnotificacion,
      codigointerno: sucursal.codigointerno,
      active: sucursal.active ? "Sí" : "No",
      acciones: (
        <div>
          <Button color="danger" onClick={() => openEditModal(sucursal)}>
            Modificar
          </Button>
          <Button
            color="info"
            onClick={() => fetchUsuariosAsignados(sucursal._id)}
          >
            Ver Usuarios
          </Button>
          <Button
            color="success"
            onClick={() => openAssignModal(sucursal._id, sucursal.empresa)}
          >
            Asignar Usuarios
          </Button>
        </div>
      ),
    })),
  }

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected)
  }

  const dataTableOptions = {
    lengthMenu: "Mostrar _MENU_ registros",
    zeroRecords: "No se encontraron registros coincidentes",
    info: "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
    infoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
    infoFiltered: "(filtrado de un total de _MAX_ registros)",
    search: "Buscar:",
    paginate: {
      first: "Primero",
      last: "Último",
      next: "Siguiente",
      previous: "Anterior",
    },
    aria: {
      sortAscending: ": activar para ordenar la columna de manera ascendente",
      sortDescending: ": activar para ordenar la columna de manera descendente",
    },
  }

  const customStyles = {
    control: provided => ({
      ...provided,
      zIndex: 1000,
    }),
    menu: provided => ({
      ...provided,
      zIndex: 1050,
    }),
  }

  const empresaOptions = empresas.map(empresa => ({
    value: empresa.idempresa._id,
    label: empresa.idempresa.nombre,
  }))

  const usuarioOptions = allUsuarios.map(usuario => ({
    value: usuario._id,
    label: `${usuario.nombre} ${usuario.apellido}`,
  }))

  const handleNitreceptorChange = selectedOption => {
    setNitreceptor(selectedOption ? selectedOption.value : "")
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setNewSucursal(prevState => ({ ...prevState, [name]: value }))
  }

  const handleEmpresaChange = selectedOption => {
    setNewSucursal(prevState => ({
      ...prevState,
      empresa: selectedOption ? selectedOption.value : "",
    }))
  }

  const handleUsuarioChange = selectedOption => {
    setSelectedUsuarios(selectedOption || [])
  }

  const resetForm = () => {
    setNewSucursal({
      empresa: "",
      nombre: "",
      direccion: "",
      active: true,
      mailnotificacion: "",
      codigointerno: "",
    })
  }

  const handleSubmitSucursal = async () => {
    try {
      await axios.post(
        "https://timbrador.ls-da.com/recepcion/hub-latam/sucursales/registro",
        newSucursal,
      )
      //console.log("Sucursal creada:", response.data)
      setModalOpen(false)
      setSuccessMessage("Sucursal creada exitosamente")
      setTimeout(() => setSuccessMessage(""), 3000) // Ocultar mensaje después de 3 segundos
      fetchSucursales() // Refrescar la lista de sucursales
      resetForm() // Limpiar el formulario
    } catch (error) {
      console.error("Error creando sucursal:", error)
      setErrorMessage(error.response?.data?.msg || "Error creando sucursal")
      setTimeout(() => setErrorMessage(""), 3000) // Ocultar mensaje después de 3 segundos
    }
  }

  const openEditModal = sucursal => {
    //console.log("sucursal: ", sucursal)

    setSelectedSucursalId(sucursal._id)
    setNewSucursal({
      empresa: sucursal.empresa,
      nombre: sucursal.nombre,
      direccion: sucursal.direccion,
      active: sucursal.active,
      mailnotificacion: sucursal.mailnotificacion,
      codigointerno: sucursal.codigointerno,
    })
    setIsEditMode(true)
    setModalOpen(true)
  }

  const openAssignModal = (sucursalId, empresaId) => {
    setSelectedSucursalId(sucursalId)
    setSelectedEmpresaId(empresaId) // Establecer el ID de la empresa seleccionada
    fetchAllUsuarios(empresaId) // Obtener todos los usuarios de la empresa
    setModalAssignUsuariosOpen(true) // Abrir el modal de asignación de usuarios
  }

  const handleUpdateSucursal = async () => {
    try {
      await axios.patch(
        `https://timbrador.ls-da.com/recepcion/hub-latam/sucursales/${selectedSucursalId}`,
        newSucursal,
      )
      //console.log("Sucursal actualizada:", response.data)
      setModalOpen(false)
      setSuccessMessage("Sucursal actualizada exitosamente")
      setTimeout(() => setSuccessMessage(""), 3000) // Ocultar mensaje después de 3 segundos
      fetchSucursales() // Refrescar la lista de sucursales
      resetForm() // Limpiar el formulario
      setIsEditMode(false)
      setSelectedSucursalId(null)
    } catch (error) {
      console.error("Error actualizando sucursal:", error)
      setErrorMessage(
        error.response?.data?.msg || "Error actualizando sucursal",
      )
      setTimeout(() => setErrorMessage(""), 3000) // Ocultar mensaje después de 3 segundos
    }
  }

  const handleAssignUsuarios = async () => {
    try {
      await axios.post(
        `https://timbrador.ls-da.com/recepcion/hub-latam/sucursales/${selectedSucursalId}/asignar-usuarios`,
        { usuarios: selectedUsuarios.map(u => u.value) },
      )
      //console.log("Usuarios asignados:", response.data)
      setModalAssignUsuariosOpen(false) // Cerrar el modal de asignación de usuarios
      setSuccessMessage("Usuarios asignados exitosamente")
      setTimeout(() => setSuccessMessage(""), 3000) // Ocultar mensaje después de 3 segundos
      fetchSucursales() // Refrescar la lista de sucursales
      setSelectedUsuarios([]) // Limpiar la selección de usuarios
    } catch (error) {
      console.error("Error asignando usuarios:", error)
      setErrorMessage(error.response?.data?.msg || "Error asignando usuarios")
      setTimeout(() => setErrorMessage(""), 3000) // Ocultar mensaje después de 3 segundos
    }
  }

  const handleRemoveUsuario = async (sucursalId, usuarioId) => {
    try {
      await axios.delete(
        `https://timbrador.ls-da.com/recepcion/hub-latam/sucursales/${sucursalId}/usuarios/${usuarioId}`,
      )
      //console.log("Usuario eliminado de la sucursal:", response.data)
      setSuccessMessage("Usuario eliminado de la sucursal exitosamente")
      setTimeout(() => setSuccessMessage(""), 3000) // Ocultar mensaje después de 3 segundos
      fetchUsuariosAsignados(sucursalId) // Refrescar la lista de usuarios asignados
    } catch (error) {
      console.error("Error eliminando usuario de la sucursal:", error)
      setErrorMessage(
        error.response?.data?.msg || "Error eliminando usuario de la sucursal",
      )
      setTimeout(() => setErrorMessage(""), 3000) // Ocultar mensaje después de 3 segundos
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <CardTitle className="h4">Sucursales</CardTitle>
                <div>
                  <Button
                    style={{
                      backgroundColor: "#007ea7",
                      color: "white",
                      border: "1px solid rgb(46, 58, 73)",
                    }}
                    variant="success"
                    onClick={handleExportToExcel}
                    className="me-2"
                  >
                    Exportar a Excel
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#007ea7",
                      color: "white",
                      border: "1px solid rgb(46, 58, 73)",
                    }}
                    variant="success"
                    onClick={() => {
                      resetForm() // Limpiar formulario al abrir el modal
                      setIsEditMode(false)
                      setModalOpen(true)
                    }}
                    className="me-2"
                  >
                    Crear Sucursal
                  </Button>
                  <Button color="danger" onClick={() => navigate("/portal")}>
                    Salir
                  </Button>
                </div>
              </div>
              <p className="card-title-desc">
                Visualice y gestione la información de las sucursales
                disponibles en el sistema.
              </p>
              <hr />
              {successMessage && (
                <Alert color="success" toggle={() => setSuccessMessage("")}>
                  {successMessage}
                </Alert>
              )}
              {errorMessage && (
                <Alert color="danger" toggle={() => setErrorMessage("")}>
                  {errorMessage}
                </Alert>
              )}
              <Form onSubmit={handleSubmit} inline>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="nitreceptor">NIT Receptor</Label>
                      <Select
                        id="nitreceptor"
                        value={empresaOptions.find(
                          option => option.value === nitreceptor,
                        )}
                        onChange={handleNitreceptorChange}
                        options={empresaOptions}
                        classNamePrefix="select2-selection"
                        placeholder="Seleccione una empresa"
                        isClearable
                        required
                        styles={customStyles} // Aplica los estilos personalizados
                      />
                    </FormGroup>
                  </Col>

                  <Col
                    md={1}
                    className="d-flex align-items-end justify-content-end"
                  >
                    <Button type="submit" color="primary">
                      Consultar
                    </Button>
                  </Col>
                </Row>
              </Form>
              <hr />

              {loading ? (
                <p>Cargando...</p>
              ) : (
                <MDBDataTable
                  responsive
                  bordered
                  data={data}
                  entriesLabel="Mostrar registros"
                  searchLabel="Buscar:"
                  paginationLabel={["Anterior", "Siguiente"]}
                  infoLabel={[
                    "Mostrando registros del",
                    "al",
                    "de un total de",
                    "registros",
                  ]}
                  language={dataTableOptions}
                  paging={false}
                  displayEntries
                />
              )}
            </CardBody>
          </Card>

          <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
            <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
              {isEditMode ? "Modificar Sucursal" : "Crear Sucursal"}
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label for="empresa">Empresa</Label>
                  <Select
                    id="empresa"
                    value={empresaOptions.find(
                      option => option.value === newSucursal.empresa,
                    )}
                    onChange={handleEmpresaChange}
                    options={empresaOptions}
                    classNamePrefix="select2-selection"
                    placeholder="Seleccione una empresa"
                    isClearable
                    required
                    styles={customStyles}
                    isDisabled={isEditMode} // Deshabilitar el campo en modo edición
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="nombre">Nombre</Label>
                  <Input
                    type="text"
                    id="nombre"
                    name="nombre"
                    value={newSucursal.nombre}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="direccion">Dirección</Label>
                  <Input
                    type="text"
                    id="direccion"
                    name="direccion"
                    value={newSucursal.direccion}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="mailnotificacion">Correo de Notificación</Label>
                  <Input
                    type="email"
                    id="mailnotificacion"
                    name="mailnotificacion"
                    value={newSucursal.mailnotificacion}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="codigointerno">Código Interno</Label>
                  <Input
                    type="text"
                    id="codigointerno"
                    name="codigointerno"
                    value={newSucursal.codigointerno}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={
                  isEditMode ? handleUpdateSucursal : handleSubmitSucursal
                }
              >
                {isEditMode ? "Actualizar" : "Crear"}
              </Button>
              <Button color="secondary" onClick={() => setModalOpen(false)}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>

          {/* Modal para mostrar los usuarios asignados a la sucursal */}
          <Modal
            isOpen={modalUsuariosOpen}
            toggle={() => setModalUsuariosOpen(!modalUsuariosOpen)}
          >
            <ModalHeader
              toggle={() => setModalUsuariosOpen(!modalUsuariosOpen)}
            >
              Usuarios Asignados
            </ModalHeader>
            <ModalBody>
              {usuariosAsignados.length > 0 ? (
                <ul>
                  {usuariosAsignados.map(usuario => (
                    <li key={usuario._id}>
                      {usuario.nombre} {usuario.apellido} - {usuario.email}
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() =>
                          handleRemoveUsuario(selectedSucursalId, usuario._id)
                        }
                      >
                        Eliminar
                      </Button>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No hay usuarios asignados a esta sucursal.</p>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => setModalUsuariosOpen(false)}
              >
                Cerrar
              </Button>
            </ModalFooter>
          </Modal>

          {/* Modal para asignar usuarios a la sucursal */}
          <Modal
            isOpen={modalAssignUsuariosOpen}
            toggle={() => setModalAssignUsuariosOpen(!modalAssignUsuariosOpen)}
          >
            <ModalHeader
              toggle={() =>
                setModalAssignUsuariosOpen(!modalAssignUsuariosOpen)
              }
            >
              Asignar Usuarios
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label for="usuarios">Seleccionar Usuarios</Label>
                  <Select
                    id="usuarios"
                    value={selectedUsuarios}
                    onChange={handleUsuarioChange}
                    options={usuarioOptions}
                    isMulti
                    classNamePrefix="select2-selection"
                    placeholder="Seleccione usuarios"
                    styles={customStyles}
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleAssignUsuarios}>
                Asignar
              </Button>
              <Button
                color="secondary"
                onClick={() => setModalAssignUsuariosOpen(false)}
              >
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>

          <Row>
            <Col md={12}>
              <ReactPaginate
                previousLabel={"← Anterior"}
                nextLabel={"Siguiente →"}
                pageCount={Math.ceil(sucursales.length / itemsPerPage)}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  return {
    user: state.Login.user,
  }
}

export default connect(mapStatetoProps, { setBreadcrumbItems })(Sucursales)
