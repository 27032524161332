import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Empresas
import ConsultarEmpresas from "../pages/Empresas/ConsultarEmpresas"
import Sucursales from "../pages/Empresas/Sucursales"
import Empresa from "../pages/Empresas/Empresa"
import CrearEmpresa from "../pages/Empresas/CrearEmpresa"

//Comprobantes
import Comprobantes from "../pages/Comprobantes/Comprobantes"
import ConsultaEventosDian from "../pages/Comprobantes/ConsultaEventosDia"
import CargaDIAN from "../pages/Comprobantes/CargarArchivo"
import CargaPDF from "../pages/Comprobantes/CargarPDF"

//Administracion
import RegistrarUsuario from "../pages/Administracion/RegistrarUsuario"
import AdminUser from "../pages/Configuracion/AdminUsuarios"

//Empresa
import RegistrarSucursales from "../pages/Empresas/Sucursales"

//Configuracion
import Menu from "../pages/Configuracion/AdminMenu"

//Extra Pages
import PagesBlank from "../pages/Extra Pages/pages-blank"
import Pages404 from "../pages/Extra Pages/pages-404"
import Pages500 from "../pages/Extra Pages/pages-500"

const userRoutes = [
  { path: "/portal", component: <Dashboard /> },

  // // //Empresas
  { path: "/portal/consultar-empresas", component: <ConsultarEmpresas /> },
  { path: "/portal/sucursales/:id", component: <Sucursales /> },
  { path: "/portal/empresa/:id", component: <Empresa /> },
  { path: "/portal/empresa/crear", component: <CrearEmpresa /> },

  // // //Comprobantes
  { path: "/portal/consultar-comprobantes", component: <Comprobantes /> },
  { path: "/portal/reporte-comprobantes", component: <ConsultaEventosDian /> },
  { path: "/portal/carga-dian", component: <CargaDIAN /> },
  { path: "/portal/carga-pdf", component: <CargaPDF /> },

  { path: "/portal/sucursales", component: <RegistrarSucursales /> },

  // // //Admoinistracion
  { path: "/portal/registro", component: <RegistrarUsuario /> },
  { path: "/portal/adminuser", component: <AdminUser /> },

  //Configuracion
  { path: "/portal/menu", component: <Menu /> },

  // // //profile
  { path: "/profile", component: <UserProfile /> },

  { path: "/pages-blank", component: <PagesBlank /> },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: <Navigate to="/portal" />,
  },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
]

export { userRoutes, authRoutes }
