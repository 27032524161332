import React, { useState, useEffect } from "react"
import axios from "axios"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert,
} from "reactstrap"
import Select from "react-select"
import { useNavigate } from "react-router-dom"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import "./RegistrarUsuario.css"
import Switch from "react-switch"

const RegistrarUsuario = props => {
  const navigate = useNavigate()

  document.title = "Registrar Usuario | LS-DA - Softlogy"

  const [nombre, setNombre] = useState("")
  const [apellido, setApellido] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [selectedEmpresas, setSelectedEmpresas] = useState([])
  const [empresas, setEmpresas] = useState([])
  const [role, setRole] = useState("Usuario") // Valor por defecto
  const [loading, setLoading] = useState(false)
  const [active, setActivo] = useState(true)
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  const fetchEmpresas = async () => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    if (!currentUser || !currentUser.empresas) {
      //console.log("User data not fully available yet, skipping fetch.")
      return
    }
    try {
      setEmpresas(currentUser.empresas)
    } catch (error) {
      console.error("Error fetching empresas data:", error)
    }
  }

  useEffect(() => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null
    if (currentUser) {
      const userGreeting = `Bienvenido, ${currentUser ? `${currentUser.nombre} ${currentUser.apellido}` : "Usuario"}`

      const breadcrumbItems = [
        { title: "LS-DA", link: "#" },
        { title: "Registrar Usuario", link: "#" },
      ]
      props.setBreadcrumbItems(userGreeting, breadcrumbItems)
    }
    fetchEmpresas()
  }, [props])

  const empresaOptions = empresas.map(empresa => ({
    value: empresa.idempresa._id,
    label: empresa.idempresa.nombre,
  }))

  const roleOptions = [
    { value: "Administrador", label: "Administrador" },
    { value: "Usuario", label: "Usuario" },
  ]

  const handleEmpresaChange = selectedOptions => {
    setSelectedEmpresas(selectedOptions || [])
  }

  const handleRoleChange = selectedOption => {
    setRole(selectedOption ? selectedOption.value : "Usuario")
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    setSuccessMessage("")
    setErrorMessage("")

    const userData = {
      nombre,
      apellido,
      email,
      password,
      empresas: selectedEmpresas.map(option => ({
        idempresa: option.value,
      })),
      role,
      active,
    }

    try {
      const response = await axios.post(
        `https://timbrador.ls-da.com/recepcion/hub-latam/auth/registro`,
        userData,
      )
      //console.log("User registered:", response.data)
      setSuccessMessage("Usuario registrado con éxito")
      setNombre("")
      setApellido("")
      setEmail("")
      setPassword("")
      setSelectedEmpresas([])
      setRole("Usuario")
      setActivo(true)
    } catch (error) {
      console.error("Error registering user:", error)
      setErrorMessage("Error al registrar el usuario")
    } finally {
      setLoading(false)
    }
  }

  const customStyles = {
    control: provided => ({
      ...provided,
      zIndex: 1000,
    }),
    menu: provided => ({
      ...provided,
      zIndex: 1050,
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <CardTitle className="h4">Registrar Usuario</CardTitle>
                <Button color="danger" onClick={() => navigate("/portal")}>
                  Salir
                </Button>
              </div>
              <p className="card-title-desc">
                Complete el formulario para registrar un nuevo usuario.
              </p>
              {successMessage && (
                <UncontrolledAlert color="success">
                  {successMessage}
                </UncontrolledAlert>
              )}
              {errorMessage && (
                <UncontrolledAlert color="danger">
                  {errorMessage}
                </UncontrolledAlert>
              )}
              <hr />
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="nombre">Nombre</Label>
                      <Input
                        type="text"
                        id="nombre"
                        value={nombre}
                        onChange={e => setNombre(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="apellido">Apellido</Label>
                      <Input
                        type="text"
                        id="apellido"
                        value={apellido}
                        onChange={e => setApellido(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Input
                        type="email"
                        id="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="password">Contraseña</Label>
                      <Input
                        type="password"
                        id="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="role">Rol</Label>
                      <Select
                        id="role"
                        value={roleOptions.find(
                          option => option.value === role,
                        )}
                        onChange={handleRoleChange}
                        options={roleOptions}
                        classNamePrefix="select2-selection"
                        placeholder="Seleccione un rol"
                        styles={customStyles}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label>Activo</Label>
                      <Row>
                        <Switch
                          onColor="#02a499"
                          value={active}
                          onChange={setActivo}
                          checked={active}
                        />
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="IdEmpresa">Empresas</Label>
                      <Select
                        id="IdEmpresa"
                        value={selectedEmpresas}
                        onChange={handleEmpresaChange}
                        options={empresaOptions}
                        classNamePrefix="select2-selection"
                        placeholder="Seleccione una o más empresas"
                        isMulti
                        styles={customStyles}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button type="submit" color="primary" disabled={loading}>
                  {loading ? "Registrando..." : "Registrar"}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    authData: state.Login.user,
  }
}

export default connect(mapStateToProps, { setBreadcrumbItems })(
  RegistrarUsuario,
)
