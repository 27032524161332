import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Form,
  Alert,
  Input,
  FormFeedback,
} from "reactstrap"
import logoDark from "../../assets/images/Logo ORIONIS_B.png"
import logoLight from "../../assets/images/lsda-logo.png"
import bandas from "../../assets/images/fondo Orionis.jpg"

import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import PropTypes from "prop-types"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"

// actions
import { loginUser, socialLogin } from "../../store/actions"

const selectLoginState = state => state.Login

const makeSelectLoginError = () =>
  createSelector(selectLoginState, login => login.error)

const Login = props => {
  document.title = "Login | LS-DA / Softlogy"

  const dispatch = useDispatch()
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "" || "",
      password: "" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Ingresar Mail"),
      password: Yup.string().required("Ingresar Password"),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.router.navigate))
    },
  })

  const selectLoginError = makeSelectLoginError()
  const error = useSelector(state => selectLoginError(state))

  useEffect(() => {
    if (error) {
      setErrorMessage("Usuario o contraseña incorrecto")
      setShowError(true)
    } else {
      setShowError(false)
    }
  }, [error])

  const signIn = type => {
    dispatch(socialLogin(type, props.router.navigate))
  }

  return (
    <React.Fragment>
      {/* Imagen de fondo (bandas) */}
      <img
        src={bandas}
        alt="bandas"
        style={{
          position: "absolute",
          top: 0,
          right: 0, // Posiciona la imagen en la parte derecha
          width: "60%", // Ajusta el tamaño de la imagen (puedes modificar este valor)
          height: "60%",
          zIndex: 1, // Se coloca detrás del contenido
          objectFit: "cover", // Ajusta la imagen para cubrir el área sin distorsionarse
        }}
      />
      <div
        className="account-pages my-5 pt-sm-5"
        style={{
          position: "relative",

          height: "100vh",
        }} // Fondo blanco y altura completa
      >
        {/* Contenido principal */}
        <Container
          style={{
            position: "relative",
            zIndex: 2,
          }}
        >
          {" "}
          {/* Se coloca por encima de la imagen */}
          <Row className="justify-content-center">
            <Link to="/" className="d-block auth-logo">
              <img
                src={logoLight}
                alt=""
                height="100"
                className="auth-logo-dark"
              />
            </Link>
            <Col md={8} lg={6} xl={5}>
              <Card
                className="overflow-hidden"
                style={{
                  backgroundColor: "#3e5672",
                  borderRadius: "8px",
                  padding: "20px",
                }}
              >
                <CardBody className="pt-0">
                  <h3 className="text-center mt-2 mb-1">
                    <img
                      src={logoDark}
                      alt=""
                      height="140"
                      className="auth-logo-dark"
                    />
                  </h3>

                  <div className="p-3">
                    <h4
                      className="font-size-18 mb-1 text-center"
                      style={{ color: "#ffffff" }}
                    >
                      Bienvenido
                    </h4>
                    <p className="text-center" style={{ color: "#ffffff" }}>
                      Ingresa al portal de LS-DA.
                    </p>

                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={e => {
                        e.preventDefault()
                        setShowError(false)
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {showError && errorMessage ? (
                        <Alert color="danger">{errorMessage}</Alert>
                      ) : null}
                      <div className="mb-3">
                        <Label htmlFor="username" style={{ color: "#ffffff" }}>
                          Usuario
                        </Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Ingresa tu email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label
                          htmlFor="userpassword"
                          style={{ color: "#ffffff" }}
                        >
                          Contraseña
                        </Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Ingresa tu contraseña"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3 mt-4">
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                          </div>
                        </div>
                        <div className="col-6 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Ingreso
                          </button>
                        </div>
                      </Row>
                      <Row className="form-group mb-0"></Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                © {new Date().getFullYear()} LS-DA S.A.S{" "}
                <span className="d-none d-sm-inline-block"></span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
