import React from "react"
import { Table, Card, CardBody } from "reactstrap"

const LatestTransactions = ({ transactions }) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">Resumen Transacciones</h4>
          <div className="table-responsive">
            <Table className="align-middle table-centered table-vertical table-nowrap">
              <thead>
                <tr>
                  <th>AÑO</th>
                  <th>EMPRESA</th>
                  <th>TIPO DOCUMENTO</th>
                  <th>CANTIDAD</th>
                  <th>IVA</th>
                  <th>INC</th>
                  <th>SUBTOTAL</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction, key) => (
                  <tr key={key}>
                    <td>{transaction.year}</td>
                    <td>{transaction.nombreEmpresa}</td>
                    <td>{transaction.tipodoc}</td>
                    <td>{transaction.cantidad}</td>
                    <td>${transaction.subtotal}</td>
                    <td>${transaction.IVA}</td>
                    <td>${transaction.INC}</td>
                    <td>${transaction.total}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default LatestTransactions
