import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
} from "reactstrap"
import axios from "axios"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"

const AdminUsuarios = props => {
  const [usuarios, setUsuarios] = useState([])
  const [selectedUsuario, setSelectedUsuario] = useState(null)
  const [menuOptions, setMenuOptions] = useState([])
  const [userMenuSelections, setUserMenuSelections] = useState({})

  useEffect(() => {
    fetchUsuarios()
    fetchMenuOptions()
  }, [])

  const fetchUsuarios = async () => {
    try {
      const response = await axios.get(
        "https://timbrador.ls-da.com/recepcion/hub-latam/usuarios",
      )
      setUsuarios(response.data)
    } catch (error) {
      console.error("Error fetching usuarios:", error)
    }
  }

  const fetchMenuOptions = async () => {
    try {
      const response = await axios.get(
        "https://timbrador.ls-da.com/recepcion/hub-latam/menu/options",
      )
      setMenuOptions(response.data)
    } catch (error) {
      console.error("Error fetching menu options:", error)
    }
  }

  const handleUsuarioChange = e => {
    const usuarioId = e.target.value
    const usuario = usuarios.find(u => u._id === usuarioId)
    setSelectedUsuario(usuario)
    const initialSelections = {}
    usuario.menuOptions.forEach(menuOptionId => {
      initialSelections[menuOptionId] = {}
    })
    setUserMenuSelections(initialSelections)
  }

  const handleMenuSelection = (menuId, subMenuId = null, subNivelId = null) => {
    const selections = { ...userMenuSelections }

    if (subNivelId) {
      // Selección de subnivel
      if (!selections[menuId]) {
        selections[menuId] = {}
      }
      if (!selections[menuId][subMenuId]) {
        selections[menuId][subMenuId] = []
      }
      const index = selections[menuId][subMenuId].indexOf(subNivelId)
      if (index === -1) {
        selections[menuId][subMenuId].push(subNivelId)
      } else {
        selections[menuId][subMenuId].splice(index, 1)
      }
    } else if (subMenuId) {
      // Selección de submenú
      if (!selections[menuId]) {
        selections[menuId] = {}
      }
      if (Array.isArray(selections[menuId][subMenuId])) {
        delete selections[menuId][subMenuId]
      } else {
        selections[menuId][subMenuId] = []
      }
    } else {
      // Selección de menú principal
      if (selections[menuId] && Object.keys(selections[menuId]).length === 0) {
        delete selections[menuId]
      } else {
        selections[menuId] = {}
      }
    }

    setUserMenuSelections(selections)
  }

  const saveUserMenuSelections = async () => {
    try {
      const { _id: userId } = selectedUsuario

      console.log("User ID:", userId)
      console.log(
        "User menu selections (menuOptions):",
        JSON.stringify(userMenuSelections, null, 2),
      )

      await axios.put(
        `https://timbrador.ls-da.com/recepcion/hub-latam/usuarios/menu-optionsEmpresa?userId=${userId}`,
        {
          menuOptions: Object.keys(userMenuSelections),
        },
      )
      alert("Menús actualizados correctamente para todas las empresas")
    } catch (error) {
      console.error("Error saving user menu selections:", error)
    }
  }

  const renderSubLevels = (children, menuId, subMenuId) => {
    return children.map(child => (
      <tr key={child._id}>
        <td style={{ paddingLeft: "40px" }}>
          <Input
            type="checkbox"
            id={`${menuId}-${subMenuId}-${child._id}`}
            checked={
              userMenuSelections[menuId] &&
              userMenuSelections[menuId][subMenuId] &&
              userMenuSelections[menuId][subMenuId].includes(child._id)
            }
            onChange={() => handleMenuSelection(menuId, subMenuId, child._id)}
          />
          {`${child.title} - ${child.path}`}
        </td>
      </tr>
    ))
  }

  const renderMenuOptions = options => {
    return options.map(option => (
      <React.Fragment key={option._id}>
        <tr>
          <td>
            <Input
              type="checkbox"
              id={`${option._id}`}
              checked={userMenuSelections[option._id] !== undefined}
              onChange={() => handleMenuSelection(option._id)}
            />
            {`${option.title} - ${option.path}`}
          </td>
        </tr>
        {option.children && option.children.length > 0 && (
          <React.Fragment>
            {option.children.map(child => (
              <React.Fragment key={child._id}>
                <tr>
                  <td style={{ paddingLeft: "20px" }}>
                    <Input
                      type="checkbox"
                      id={`${option._id}-${child._id}`}
                      checked={
                        userMenuSelections[option._id] &&
                        userMenuSelections[option._id][child._id] !== undefined
                      }
                      onChange={() =>
                        handleMenuSelection(option._id, child._id)
                      }
                    />
                    {`${child.title} - ${child.path}`}
                  </td>
                </tr>
                {child.children && child.children.length > 0 && (
                  <React.Fragment>
                    {renderSubLevels(child.children, option._id, child._id)}
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
      </React.Fragment>
    ))
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <h4 className="card-title">Administrar Usuarios</h4>
              <Form>
                <FormGroup>
                  <Label for="usuarioSelect">Seleccione un Usuario</Label>
                  <Input
                    type="select"
                    id="usuarioSelect"
                    onChange={handleUsuarioChange}
                  >
                    <option value="">Seleccionar Usuario</option>
                    {usuarios.map(usuario => (
                      <option key={usuario._id} value={usuario._id}>
                        {usuario.nombre} {usuario.apellido}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Form>

              {selectedUsuario && (
                <div>
                  <h5 className="mt-4">Configurar Menús</h5>
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>Menú</th>
                      </tr>
                    </thead>
                    <tbody>{renderMenuOptions(menuOptions)}</tbody>
                  </Table>

                  <Button
                    color="primary"
                    onClick={saveUserMenuSelections}
                    className="mt-3"
                  >
                    Guardar Cambios
                  </Button>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  return {
    user: state.Login.user,
  }
}

export default connect(mapStatetoProps, { setBreadcrumbItems })(AdminUsuarios)
